var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 bg-login",attrs:{"lg":"8"}}),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[(!this.registerSuccess)?_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Register_private_area"))+" ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":_vm.$t('User.Name'),"label-for":"user-name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user-name","state":errors.length > 0 ? false : null,"name":"user-name","placeholder":""},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3546578267)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('User.Last_name'),"label-for":"user-lastname"}},[_c('validation-provider',{attrs:{"name":"Lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user-lastname","state":errors.length > 0 ? false : null,"name":"user-lastname","placeholder":""},model:{value:(_vm.userLastname),callback:function ($$v) {_vm.userLastname=$$v},expression:"userLastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2139747825)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.$t("Password")))])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required|password|confirmed:Confirm_Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3337449752)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-confirm-password"}},[_vm._v(_vm._s(_vm.$t("Confirm_Password")))])]),_c('validation-provider',{attrs:{"name":"Confirm_Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-confirm_password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-confirm-password","placeholder":"············"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4274923352)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Register"))+" ")])],1)],1)],1):_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Register_private_area_success"))+" ")]),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.goToLogin.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("GoToLogin"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }