<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5 bg-login">
      </b-col>
      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
          v-if="!this.registerSuccess"
        >
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Register_private_area") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- name -->
              <b-form-group :label="$t('User.Name')" label-for="user-name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="user-name"
                    v-model="userName"
                    :state="errors.length > 0 ? false : null"
                    name="user-name"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- lastname -->
              <b-form-group
                :label="$t('User.Last_name')"
                label-for="user-lastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lastname"
                  rules="required"
                >
                  <b-form-input
                    id="user-lastname"
                    v-model="userLastname"
                    :state="errors.length > 0 ? false : null"
                    name="user-lastname"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--  password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t("Password") }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|password|confirmed:Confirm_Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- confirm password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-confirm-password">{{
                    $t("Confirm_Password")
                  }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Confirm_Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-confirm_password"
                      v-model="confirmPassword"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-confirm-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click.prevent="validationForm"
              >
                {{ $t("Register") }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto" v-else>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Register_private_area_success") }}
          </b-card-text>
          <b-button
            type="submit"
            variant="primary"
            block
            @click.prevent="goToLogin"
          >
            {{ $t("GoToLogin") }}
          </b-button>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email, digits } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { user, logout } from "@api/auth";
import { acceptInvite } from "@api/user";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: "",
      confirmPassword: "",
      userName: "",
      userLastname: "",
      registerSuccess: false,
      sideImg: require("@/assets/images/logo/logo-fairers-gradient.svg"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    this.getUser();
  },
  mounted() {
    console.log(this.$route);
  },
  methods: {
    goToLogin() {
      this.$router.push({ path: "/" });
    },
    validationForm() {
      const $this = this;
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          let formData = {
            token: $this.$route.params.code,
            name: $this.userName,
            last_name: $this.userLastname,
            password: $this.password,
            password_confirmation: $this.confirmPassword,
          };
          acceptInvite(formData)
            .then((data) => {
              $this.registerSuccess = true;
            })
            .catch((error) => {
              $this.$store.commit("unset_user");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Invito non valido",
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    getUser() {
      const $this = this;
      user()
        .then((data) => {
          if (["admin", "editor"].includes(data.role)) {
            logout().then(() => {
              $this.$store.commit("unset_user");
            });
          } else {
            $this.$store.commit("unset_user");
          }
        })
        .catch(() => {
          $this.$store.commit("unset_user");
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
